<script setup lang='ts'>

</script>

<template>
  <div class="text-center mt-3 text-3.5">
    登录即表示你同意  <NuxtLink to="/docs/register-protocol" text-primary>
      《用户协议》
    </NuxtLink>
  </div>
</template>
